<template>
  <div v-can="'RepMedExt1'">
    <section class="header">
      <div class="title">Extratos</div>
      <b-dropdown
        v-can="'RepMedExt3'"
        v-show="selecteds.length && verifyAllFinished"
        text="Alterar status"
        variant="outline-primary"
        class="ml-auto mr-2"
      >
        <b-dropdown-item @click="updateStatus(null, 'Conferido')">
          Conferido
        </b-dropdown-item>
        <b-dropdown-item @click="updateStatus(null, 'Enviado')">
          Enviado
        </b-dropdown-item>
        <b-dropdown-item @click="updateStatus(null, 'A revisar')">
          A revisar
        </b-dropdown-item>
      </b-dropdown>
      <b-button 
        v-can="'RepMedExt2'"
        @click="asyncPrint"
        variant="primary"
      > 
        Download 
      </b-button>
    </section>

    <section>
      <Filters @changeFilters="changeFilters" />
    </section>

    <section>
      <Table
        :headers="headers"
        :extracts="extracts"
        :checkable="true"
        :updateStatus="updateStatus"
        @selected="changeSelected"
        @delete-extract="getExtracts"
        :openExtractModal="openExtractModal"
        :openAttachedFilesModal="openAttachedFilesModal"
      />
    </section>

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>

    <AttachedFilesModal
      :files.sync="files"
      @onHide="onHideAttachedFilesModal"
      :extractId="extractId"
    />

    <ExtractModal
      v-if="extractId"
      :refreshModal="refreshModal"
      :extractId="extractId"
      @closeModal="onHideExtractModal"
      :updateStatus="updateStatus"
      :updateOnledingStatus="updateOnledingStatus"
      :updateOnledingExtract="updateOnledingExtract"
      @removeOnlendingFromExtract="removeOnlendingFromExtract"
    />
  </div>
</template>

<script>
import api from '../api'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper'
// import { saveAs } from 'file-saver'

export default {
  name: 'Extracts',
  components: {
    AttachedFilesModal: () => import('@/modules/onlending/modals/extract/files/AttachedFilesModal.vue'),
    Filters: () => import('@/modules/onlending/components/extract/Filters.vue'),
    ExtractModal: () => import('@/modules/onlending/modals/extract/OnlendingExtractModal'),
    Table: () => import('@/modules/onlending/components/extract/Table')
  },
  created() {
    this.getExtracts()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),

      extracts: [],
      extractId: null,
      extractToShow: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      current_page: 1,

      headers: [
        'Check',
        { label: 'Status', key: 'status' },
        { label: 'Período', key: 'period', tdClass: this.addTdClass },
        {
          label: 'N° rel. de repasse',
          key: 'report_number',
          tdClass: this.addTdClass
        },
        { label: 'Favorecido', key: 'beneficiary', tdClass: this.addTdClass },
        { label: 'Valor total', key: 'total_value', tdClass: this.addTdClass },
        {
          label: 'Valor repasse',
          key: 'transfer_value',
          tdClass: this.addTdClass
        },
        'Extrato'
      ],
      files: [],
      selecteds: [],
      filters: null,
      refreshModal: 1
    }
  },
  computed: {
    verifyAllFinished: function() {
      const hasNotFinished = this.selecteds.filter(extract => !['Finalizado', 'A pagar'].includes(extract.status))
      return hasNotFinished?.length
    }
  },
  methods: {
    parseNumberToMoney,
    getExtracts() {
      const isLoading = this.$loading.show()
      const data = {
        clinic_id: this.clinic.id,
        filters: this.filters
      }
      api.getOnlendingExtractsByClinicId(this.current_page, data)
      .then(res => {  
        this.extracts = res.data.data
        this.currentPage = res.data.current_page
        this.perPage = res.data.per_page
        this.totalRows = res.data.total
      })
      .catch(error => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },

    async updateOnledingExtract(id, data) {
      const isLoading = this.$loading.show()
      try {
        await api.updateOnlendingExtract(id, data)
        this.getExtracts()
        this.$toast.success('Extrato atualizado com sucesso')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.getExtracts()
        isLoading.hide()
      }
    },

    async updateStatus(id, status) {
      const isLoading = this.$loading.show()
      try {
        const ids = []
        if(!id){
          this.selecteds.map(extract => {
            if(!['Finalizado', 'A pagar'].includes(extract.status)) ids.push(extract.id)
          })
        }
        else{
          ids.push(id)
        }

        const params =  {
          extracts_id: ids,
          data: {
            status: status
          }
        }
        await api.changeOnlendingExtractStatus(params)
        this.extracts.filter(extract => ids.findIndex(option => option === extract.id) !== -1).forEach(extract => {
          if (ids.includes(extract.id)) {
            this.$set(extract, 'status', status)
            extract.status = status
          }
        })
        if(ids?.length) this.$toast.success('Status do extrato de repasse atualizado com sucesso')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateOnledingStatus(ids, status) {
      const isLoading = this.$loading.show();
      try {
        await api.updateOnlendingByIds({
          onlending_ids: ids,
            data: {
            status: status
            }
        })  
        this.$toast.success('Status do repasse atualizado com sucesso')
        isLoading.hide()
        this.refreshModal++
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    openExtractModal(id){
      this.extractId = id
      setTimeout(() => {
        this.$bvModal.show('onlending-extract-modal')
      }, 300);
    },
    onHideExtractModal() {
      this.extractToShow = null;
      this.getExtracts();
    },

    openAttachedFilesModal(extract){
      this.files = extract.files;
      this.extractId = extract.id
      this.$bvModal.show('attached-files-modal');
    },
    onHideAttachedFilesModal() {
      this.extract = null;
      this.getExtracts();
    },
    async deleteFile(id){
      const isLoading = this.$loading.show()
      try {
        await api.deleteOnlendingExtractFile(id)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    changeSelected(value) {
      this.selecteds = value
    },
    addTdClass(value, key, item) {
      if (item.deleted_at) {
        return 'line-through'
      }
    },

    async removeOnlendingFromExtract(id){
      const isLoading = this.$loading.show()
      try {
        await api.updateOnlending(id, {
          extract_id: null,
          status: 'Em aberto'    
        })
        this.$toast.success('Repasse removido do extratoo com sucesso')
        isLoading.hide()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },      

    asyncPrint() {
      if(!this.selecteds.length) this.$toast.warning('Para o download é necessário escolher algum extrato!')
      else{                
        this.selecteds.forEach( extract => {
          try {
            const data = {
              onlending_extract_id: extract.id,
              professional_id: this.user.id,
              clinic_id: this.clinic.id,
              type: 'async'
            }
            api.asyncPrintOnlendingExtract(data)
            this.$toast.success('Iniciando download do extrato de repasse')
          } catch (error) {
            this.$toast.error(error.message)
          }
        });
      }
    },
    changeFilters(filters){
      this.filters = filters
      this.getExtracts()
    }
  },
  watch: {
    currentPage(val) {
      this.current_page = val
      setTimeout(() => {
        this.getExtracts()    
      }, 300);
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  .title {
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: var(--type-active);
    display: flex;
    align-items: center;
  }
}
</style>